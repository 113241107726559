<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}家庭指导</span>
		</div>
		<el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="标题" prop="title">
				<el-input v-model.trim="ruleForm.title" :disabled="isShowDetail" placeholder=" 请输入"></el-input>
			</el-form-item>
			<el-form-item label="指导类型" prop="bulletinTypeDictionaryItemId">
				<el-select v-model="ruleForm.bulletinTypeDictionaryItemId" placeholder="所属社区" :disabled="isShowDetail">
					<el-option v-for="item in bulletinTypeList" :key="item.id" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="咨讯描述" prop="description">
				<el-input v-model.trim="ruleForm.description" :disabled="isShowDetail" placeholder=" 请输入"></el-input>
			</el-form-item>
			<el-form-item label="咨讯来源" prop="origin">
				<el-input v-model.trim="ruleForm.origin" :disabled="isShowDetail" placeholder=" 请输入"></el-input>
			</el-form-item>
			<el-form-item label="上传图片" prop="images">
				<Upload ref="uploadImage" v-model="ruleForm.images" @uploadImg="uploadImg" operator="zhangl" :maxCount="2" :disabled="isShowDetail"></Upload>
			</el-form-item>
			<el-form-item class="func-btn">
				<el-button type="primary" @click="onSave('ruleForm')">保存</el-button>
				<el-button @click="onResetForm('ruleForm')">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
	},
	data() {
		return {
			ruleForm: {
				title: '',
				bulletinTypeDictionaryItemId: null,
				description: '',
				origin: '',
				images: [],
			},
			createdVisible: false,
			rules: {
				title: [{ required: true, message: '请输入', trigger: 'blur' }],
				bulletinTypeDictionaryItemId: [{ required: true, message: '请选择所属社区', trigger: 'change' }],
				description: [{ required: true, message: '请输入', trigger: 'blur' }],
				origin: [{ required: true, message: '请输入', trigger: 'blur' }],
				images: [{ required: true, message: '请上传图片', trigger: 'change' }],
			},
			bulletinTypeList: [],
			saveLoading: false,
			link: '',
			attachmentInfo: [],
		};
	},
	props: ['isShowDetail'],
	watch: {},
	created() {},
	methods: {
		init(params) {
			this.createdVisible = true;
			this.link = params.link;
			this.getBulletinTypeList();
			this.getAttachment();
			if (params.row) {
				this.getDetails(params.link);
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			// 新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取知道类型
		getBulletinTypeList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { size: 9999, current: 1, dictionaryCode: 'HEALTH_GUIDANCE' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.bulletinTypeList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取详情
		async getDetails(link) {
			let res = await this.$http.get(link).then((res) => res);
			if (res.data && res.data.success) {
				let data = res.data.collection[0] || {};
				this.ruleForm = { ...data };

				this.ruleForm.images =
					(data.attachmentMap &&
						data.attachmentMap['PHOTO'].map((item) => {
							return {
								url: item.link,
								uid: item.id,
								title: item.title,
							};
						})) ||
					[];
				console.log(this.ruleForm);
				this.$forceUpdate();
			}
		},

		// 保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let params = {
						...this.ruleForm,
						healthServiceGroupId: this.$route.query.id,
						attachments: this.ruleForm.images.map((item) => {
							return {
								link: item.url,
								subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'PHOTO').subjectTypeCode,
								title: item.title,
								attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'PHOTO').id,
								mimeType: item.title.split('.')[1],
							};
						}),
					};
					let queryApi = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								this.saveLoading = false;
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.onResetForm('ruleForm');
							}
						})
						.catch(() => {
							this.saveLoading = false;
						});
				} else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
		uploadImg(dataImg) {
			this.ruleForm.images = dataImg.map((item) => {
				return {
					url: item.url,
					uid: item.uid,
					title: item.title,
				};
			});
		},
		// 重置
		onResetForm(formName) {
			this.createdVisible = false;
			this.ruleForm.medicalWorkerIdList = [{ name: '' }];
			this.$refs[formName].resetFields();
		},
		// 获取附件组
		getAttachment() {
			this.$http
				.get(this.api['AttachmentGroups#index'].href, { params: { subjectTypeCode: 'HOUSEHOLD_MESSAGE' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
	},
};
</script>

<style lang="scss" scoped>
.showBorder {
	border: 1px solid red;
}
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
}
/deep/ .el-button {
	margin-left: 16px;
}
.users-item {
	margin-bottom: 12px;
	display: grid;
	grid-template-columns: 136px 136px 136px 16px 16px;
	gap: 15px;
	i {
		line-height: 32px;
		color: #999;
		font-size: 16px;
		cursor: pointer;
	}
	i:hover {
		color: #1db9b1;
	}
	.icon-shanchu:hover {
		color: #f56c6c;
	}
}
.users-item:nth-last-child(1) {
	margin-bottom: 0;
}
.func-btn /deep/ .el-form-item__content {
	display: flex;
	justify-content: center;
}
.flex-tow {
	display: flex;
	align-items: center;
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
</style>